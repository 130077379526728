import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {ApolloClient} from 'apollo-client';
import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import {onError} from 'apollo-link-error';
import {ApolloLink, from} from 'apollo-link';
import useGeneralStore from "@/stores/general";
import {SnackBarMessageType} from "@/models/SnackBarMessageType";
import SessionTimeoutUtil from "@/util/SessionTimeoutUtil";


Vue.use(VueApollo);

const baseURL = process.env.VUE_APP_API_PATH + '/graphql';
const publicBaseURL = process.env.VUE_APP_API_PATH + '/graphql_public';

// HTTP connection to the API
const httpLink = createUploadLink({
    uri: (operation) => `${baseURL}?operationName=${operation.operationName}`,
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

const publicHttpLink = createUploadLink({
    uri: (operation) => `${publicBaseURL}?operationName=${operation.operationName}`,
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});


const responseMiddleware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        // If server responds with any data, we can postpone idle timeout
        if (response.data) {
            SessionTimeoutUtil.updateLastActivity();
        }
        return response;
    });
});


const errorLink = onError(({graphQLErrors, networkError, response}) => {
    const store = useGeneralStore();

    if (graphQLErrors && graphQLErrors.length) {

        store.addErrorSnack(response);
    } else if (networkError) {
        store.addSnack({message: networkError.message, type: SnackBarMessageType.ERROR}, false);
    }
});

// Cache implementation
const cache = new InMemoryCache({
    possibleTypes: {
        Actor: ["AdminAccount", "TechnicalAccount"]
    },
    dataIdFromObject: (object) => {
        switch (object.__typename) {
            case 'Audit':
                return `${object.__typename}:${object.key}`;
            default:
                return defaultDataIdFromObject(object); // Fall back to default
        }
    },
});

const apolloClient = new ApolloClient({
    link: from([responseMiddleware, errorLink, httpLink]),
    cache,
});

const publicApolloClient = new ApolloClient({
    link: from([errorLink, publicHttpLink]),
    cache
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    clients: {
        apolloClient,
        publicApolloClient
    }
});

export default apolloProvider;
export {apolloClient, publicApolloClient};