



























 import Vue from "vue";
 import Fetch from "@/util/Fetch";

 const api = process.env.VUE_APP_API_PATH ?? "";

 export default Vue.extend({
   data: () => ({  
     optInloading:  false as boolean,
     optin:  false as boolean,
   }),
   props: {
     listId: String,
     listName: String,
     lt: String
   },
   methods: {
       registerOptIn: function (): void {
           this.optInloading = true;
           Fetch.postForm(`${api}/marketingoptin/subscribeonlogin`, {
               "lt": this.lt,
               "optin": this.optin ? "true" : "false",
               "listId": this.listId
           }).then(response => response.json()).then(json => {
               this.$emit("continue", json);
           }).finally(() => {
               this.optInloading = false;
           });
     }
 }
});
