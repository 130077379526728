
























import Vue from "vue";
import Connector from "@/util/Connector";

export default Vue.extend({
  name: 'Notification',
  props: {
    service: {
      type: String,
      required: true
    }
  },
  data: () => ({
    reason: "",
    error: false,
    notificationSentManually: false,
    loading: false
  }),
  computed: {
    canContinue(): boolean {
      return !!this.reason.trim() || this.notificationSentManually;
    }
  },
  methods: {
    sendNotification: function (): void {
      if (this.loading) return;
      this.loading = true;
      Connector.siteAccessReason({
        service: this.service,
        reason: this.reason,
        override: this.notificationSentManually
      })
          .then(() => this.$emit("continue"))
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            this.error = true;
          })
          .finally(() => this.loading = false);
    }

  }
});
