
export default class SessionTimeoutUtil {
    public static readonly ALERT_BEFORE = 60 * 10; // Alert time in seconds before session timeout
    public static readonly SNOOZE_TIME =  60 * 4; // Snooze time in seconds (hide alert for specified amount of time before showing it again)


    private static readonly SESSION_CREATED = "session.created";
    private static readonly LAST_ACTIVE = "session.lastActive";
    private static readonly IDLE_TIMEOUT = "session.idleTimeout";
    private static readonly HARD_TIMEOUT = "session.hardTimeout";
    private static readonly SNOOZE_ALERTS_UNTIL = "session.snoozeAlertsUntil";

    static updateSessionInfo(input: {created: number, idleTimeout: number, hardTimeout: number}) {
        localStorage.setItem(SessionTimeoutUtil.SESSION_CREATED, String(input.created));
        localStorage.setItem(SessionTimeoutUtil.LAST_ACTIVE, String(Math.floor(Date.now() / 1000)));
        localStorage.setItem(SessionTimeoutUtil.IDLE_TIMEOUT, String(input.idleTimeout));
        localStorage.setItem(SessionTimeoutUtil.HARD_TIMEOUT, String(input.hardTimeout));
    }

    static updateLastActivity() {
        localStorage.setItem(SessionTimeoutUtil.LAST_ACTIVE, String(Math.floor(Date.now() / 1000)));
    }

    static timeToIdleTimeoutInSeconds(): number {
        const lastActivity = Number(localStorage.getItem(SessionTimeoutUtil.LAST_ACTIVE));
        const idleTimeout = Number(localStorage.getItem(SessionTimeoutUtil.IDLE_TIMEOUT));
        const unixTimeInSeconds = Math.floor(Date.now() / 1000);

        return Math.floor((idleTimeout - (unixTimeInSeconds - lastActivity)));
    }

    static timeToHardTimeoutInSeconds(): number {
        const sessionCreated = Number(localStorage.getItem(SessionTimeoutUtil.SESSION_CREATED));
        const hardTimeout = Number(localStorage.getItem(SessionTimeoutUtil.HARD_TIMEOUT));
        const unixTimeInSeconds = Math.floor(Date.now() / 1000);

        return Math.floor((hardTimeout - (unixTimeInSeconds - sessionCreated)));
    }

    static snoozeAlert(): void {
        const unixTimeInSeconds = Math.floor(Date.now() / 1000);
        localStorage.setItem(SessionTimeoutUtil.SNOOZE_ALERTS_UNTIL, String(unixTimeInSeconds + SessionTimeoutUtil.SNOOZE_TIME));
    }


    static isSnoozeActive(): boolean {
        const snoozeUntil = Number(localStorage.getItem(SessionTimeoutUtil.SNOOZE_ALERTS_UNTIL)) ?? 0;
        const unixTimeInSeconds = Math.floor(Date.now() / 1000);

        return snoozeUntil > unixTimeInSeconds;
    }
}
