import {LoginResponse} from "@/models/LoginResponse";

export enum Step {
    LOGIN,
    POLICY_CONSENT_REQUIRED,
    AD_TRANSITION,
    TWO_FACTOR,
    LOGOUT_REASON,
    POST_REDIRECT,
    WARN,
    ERROR,
    LOGGED_IN,
    NONE,
    EXTERNAL_IDP,
    PASSWORD_EXPIRED,
    NOTIFICATION_REQUIRED,
    MARKETING_CONSENT_REQUIRED
}

export function stepFromResult(result: string): Step {
    const loginResponse = LoginResponse[result as keyof typeof LoginResponse];
    switch (loginResponse) {
        case LoginResponse.AUTHENTICATION_FAILURE:
            return Step.ERROR;
        case LoginResponse.ERROR:
            return Step.ERROR;
        case LoginResponse.POLICY_CONSENT_REQUIRED:
            return Step.POLICY_CONSENT_REQUIRED;
        case LoginResponse.SUCCESS:
            return Step.LOGGED_IN;
        case LoginResponse.TWO_FACTOR_REQUIRED:
            return Step.TWO_FACTOR;
        case LoginResponse.SUCCESS_WITH_WARNINGS:
            return Step.WARN;
        case LoginResponse.WARN:
            return Step.WARN;
        //Eventually should be replaced with Step.ERROR, however out-of-band notification system is advised to
        // be implemented beforehand
        case LoginResponse.PASSWORD_EXPIRED:
            return Step.PASSWORD_EXPIRED;
        case LoginResponse.AD_TRANSITION:
            return Step.AD_TRANSITION;
        case LoginResponse.NOTIFICATION_REQUIRED:
            return Step.NOTIFICATION_REQUIRED;
        case LoginResponse.MARKETING_CONSENT_REQUIRED:
            return Step.MARKETING_CONSENT_REQUIRED;
        default:
            return Step.ERROR;
    }
}
