























































import Vue from "vue";
import Fetch from "@/util/Fetch";
import {Policy} from "@/graphql/generated/internal/types";
import {PropType} from "@vue/composition-api";

const api = process.env.VUE_APP_API_PATH ?? "";
const context = process.env.VUE_APP_WEB_CONTEXT ?? "";

export default Vue.extend({
  data: () => ({
    declineLoading: false,
    approveLoading: false,
    error: false,
    showPolicyDialog: false,
    selectedPolicy: undefined as Policy | undefined,
    selectedPolicyId: undefined as number | undefined,
    approvedPolicyIds: [] as number[],
  }),
  props: {
    policies: {
      required: true,
      type: Array as PropType<Policy[]>
    },
    lt: String
  },
  methods: {
    decline: function (): void {
      this.error = false;
      this.declineLoading = true;
      Fetch.postForm(api + context + "imsapi/policyconsent/decline", {})
          .then(() => {
            this.$emit("decline");
          })
          .catch(e => {
            this.$emit("errorEvent", true, e, undefined)
          })
          .finally(() => {
            this.declineLoading = false;
          });
    },
    approve: function (): void {
      this.error = false;
      this.approveLoading = true;
      const approvedPolicyIdsJson = JSON.stringify(this.approvedPolicyIds);
      Fetch.postForm(api + context + "imsapi/policyconsent/approve", {
        "lt": this.lt,
        "approvedPolicies": approvedPolicyIdsJson
      })
          .then(response => response.json())
          .then(json => {
              this.$emit("approve", json);
          })
          .catch(e => {
            this.$emit("errorEvent", true, e, "Something went wrong during policy approval")
          })
          .finally(() => {
            this.approveLoading = false;
          });
    },

    approvePolicy(policy: Policy) {
      if (!this.approvedPolicyIds.includes(policy.id)) {
        this.approvedPolicyIds.push(policy.id);
      }
      this.showPolicyDialog = false;
    },

    togglePolicyDialog(policy: Policy) {
      this.selectedPolicy = policy;
      this.showPolicyDialog = true;
    },

    formatEpochDate(epochTime: string): string {
      const epochNum = parseInt(epochTime) * 1000;
      return new Date(epochNum).toDateString();
    }

  },

  computed: {
    isContinueDisabled(): boolean {
      return this.approvedPolicyIds != undefined && this.policies != undefined && this.approvedPolicyIds.length !== this.policies.length;
    }
  }
});
