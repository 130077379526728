import {GraphQLClient} from "graphql-request";
import {getSdk} from "@/graphql/generated/internal/types";
import Fetch from "@/util/Fetch";
import {Response} from "graphql-request/dist/types";
import SessionTimeoutUtil from "@/util/SessionTimeoutUtil";

const responseMiddleware = (response: Response<unknown> | Error) => {
    // If server responds with any data, we can postpone idle timeout
    if ("data" in response) {
        SessionTimeoutUtil.updateLastActivity();
    }
}

const graphQLClient = new GraphQLClient(`${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_WEB_CONTEXT}graphql`, {
    credentials: 'include',
    mode: 'cors',
    redirect: 'follow',
    fetch: Fetch.postGraphQlForm,
    responseMiddleware: responseMiddleware
});

const Connector = getSdk(graphQLClient);

export default Connector;
